html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Muli', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

button:disabled {
  opacity: 0.5;
}

.footer-section {
  background-color: black;
  color: white;
}

#basic-navbar-nav i {
  color: black;
  font-size: 1.2rem
}

#basic-navbar-nav .st-icon-pair {
  border: 1px solid black;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.img-section {
  height: 50vh;
  min-height: 500px;
  background-image: url("https://api.ptmd.nl/rest/images/v1/original/2018-VK/2018-verlichtingVK_b2c.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.masthead {
  height: 50vh;
  min-height: 500px;
  background-image: url('/images/header.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.navBarContainer {
  background-color: white;
  box-shadow: 0px 0px 10px 5px rgba(51, 51, 51, 0.315)
}

.intro-section {
  padding: 50px 0;
}

.text-col {
  padding: 0 30px;
  font-weight: 100;
}

.text-col .top {
  font-weight: 1000;
}

.button-col {
  border-left: 1px solid lightgrey;
}

.button-col .button-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.button-col a {
  margin: 10px 0 10px 20px;
  background-color: black;
  border-radius: 0px;
}

